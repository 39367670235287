<template>
  <v-dialog v-model="dialog" max-width="1200" persistent transition="dialog-bottom-transition">
    <v-card tile>
      <v-toolbar flat dark color="primary">
        <!-- close btn -->
        <v-btn icon dark @click="$emit('close')">
          <v-icon>mdi-close</v-icon>
        </v-btn>

        <!-- title -->
        <v-toolbar-title>Clear DAGs</v-toolbar-title>
        <v-spacer></v-spacer>
      </v-toolbar>

      <v-container>
        <!-- <v-autocomplete
          solo-inverted
          :items="listDagErrors"
          multiple
          v-model="selectedDags"
          chips
          small-chips
          value
          item-text="dag_id"
          return-object
          placeholder="Selecione as Dags"
        /> -->
        <v-select v-model="selectedDags" chips item-text="dag_id" :items="listDagErrors" label="Selecione as Dags" multiple>
          <template v-slot:prepend-item>
            <v-list-item ripple @click="toggle">
              <v-list-item-action>
                <v-icon :color="selectedDags.length > 0 ? 'indigo darken-4' : ''">
                  {{ icon }}
                </v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>
                  Select All
                </v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider class="mt-2"></v-divider>
          </template>
          <template>
            <v-divider class="mb-2"></v-divider>
          </template>
        </v-select>
        <v-spacer />
        <!-- <v-btn :disabled="selectedDags.length === 0" @click="massiveDagClear" color="success">Enviar</v-btn> -->
        <v-btn disabled color="success">Enviar</v-btn>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { MUTATION_MASSIVE_CLEAR_DAGS } from '@/modules/airflow/graphql'

export default {
  props: {
    dialog: {
      type: Boolean
    },
    listDagErrors: {
      type: Array
    }
  },
  data: () => ({
    selectedDags: []
  }),
  computed: {
    all() {
      return this.selectedDags.length === this.listDagErrors.length
    },
    partial() {
      return this.selectedDags.length > 0 && !this.all
    },
    icon() {
      if (this.all) return 'mdi-close-box'
      if (this.partial) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  },
  methods: {
    async massiveDagClear() {
      try {
        await this.$apollo.mutate({
          mutation: MUTATION_MASSIVE_CLEAR_DAGS,
          variables: {
            id: this.selectedDags
          }
        })
        this.$snackbar({ message: 'Realizando clear das Dags selecionadas' })
      } catch (err) {
        console.log(err)
        this.$snackbar({ message: 'Falha ao realizar clear nas dags' })
      }
    },
    toggle() {
      this.$nextTick(() => {
        if (this.all) {
          this.selectedDags = []
        } else {
          this.selectedDags = this.listDagErrors.slice()
        }
      })
    }
  }
}
</script>
